#title {
  text-align: center;
  font-size: 54px;
  margin-bottom: 0px;
}

#author {
  text-align: center;
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}
